import React, { Component } from "react";

import rocket_logo from "../assets/logo.png";
import twitter_logo from "../assets/icons/twitter.svg";
import instagram_logo from "../assets/icons/instagram.svg";
import linkedin_logo from "../assets/icons/linkedin.svg";
import CookiesDisclaimer from "./cookies-disclaimer";

const social = [
  { icon: instagram_logo, link: "/" },
  { icon: twitter_logo, link: "/" },
  { icon: linkedin_logo, link: "/" },
];

const Footer = () => (
  <footer className="footer">
    <div className="container footer-info">
      <div className="row justify-content-between align-items-center border-bottom py-3 ">
        <div className="col-md-6 px-md-0 text-center text-md-left">
          <img src={rocket_logo} alt="rocket logo" className="rocket_logo" />
        </div>
        <div className="col-md-6 px-md-0 text-center text-md-right">
          <p>
            124 Broadkill Road STE 902 <br /> Milton, DE 19968
          </p>
        </div>
        {/*
        <div className="col-md-3 text-center d-none">
          <a href="/">Terms & Conditions</a>
        </div>
        <div className="col-md-2 text-center  justify-content-center justify-content-md-end social_media my-4 d-none d-md-flex">
          {social.map((item, key) => (
            <a key={`social_${key}`} href={item.link} className="mx-4 mx-md-2">
              <img src={item.icon} className="img-fluid" />
            </a>
          ))}
          </div>*/}
      </div>
      <div className="footer__end text-center text-md-left">
        <p className="w-100">
          {new Date().getFullYear()} Rocket Distributors, LLC
        </p>
      </div>
    </div>
    <CookiesDisclaimer />
  </footer>
);

export default Footer;
