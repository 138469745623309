import React from "react";
import { BrowserRouter, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./css/font-awesome.css";
import "./css/app.css";
import Header from "./components/header";
import Footer from "./components/footer";

import HomePage from "./components/pages/home";
import PpePage from "./components/pages/ppe";
import RocketPage from "./components/pages/brands/rocket-direct";
import BulkmaskPage from "./components/pages/brands/bulkmask-direct";
import CertificationsPage from "./components/pages/certifications";

import ScrollToTop from "./middleware/scrollTop";
import * as serviceWorker from "./serviceWorker";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <div>
          <Header />
          <Route
            exact
            path="/3ply-level3-labreport"
            render={() => (
              <CertificationsPage
                pdf="/docs/Nelson_Labs_KF-B_P05(L3)_Surgical_Mask_Report.pdf"
                title={
                  <h2 className="title-content text-center mb-3">
                    Nelson Labs&reg;
                    <br />
                    Level 3 Surgical Mask Testing Report
                  </h2>
                }
              />
            )}
          />
          <Route
            exact
            path="/3ply-level1-labreport"
            render={() => (
              <CertificationsPage
                pdf="/docs/3ply-lvl1.pdf"
                title={
                  <h2 className="title-content text-center mb-3">
                    Nelson Labs&reg;
                    <br />
                    Level 1 Surgical Mask Testing Report
                  </h2>
                }
              />
            )}
          />
          {/*<Route exact path="/ppe" component={PpePage} />*/}
          {/*<Route exact path="/brands/rocket-direct" component={RocketPage} />
          <Route
            exact
            path="/brands/bulkmask-direct"
            component={BulkmaskPage}
              />*/}
          <Route exact path="/" component={HomePage} />
          <Footer />
        </div>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
