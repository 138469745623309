class ActiveCampaignForm {
  constructor({ fields, hiddenFields, alias }) {
    this.fields = fields;
    this.hiddenFields = hiddenFields;
    this.alias = alias;
  }

  async submit(values) {
    return new Promise((resolve, reject) => {
      if (this.validate(values)) {
        // Screate script to submit form
        const APIURL = this.getFormURL(values);
        const script = document.createElement("script");
        const timeout = setTimeout(() => {
          const script = document.getElementById("ac_form_script");
          if (script !== null) {
            script.remove();
            reject("Form submission failed");
          }
        }, 20000);
        script.src = APIURL;
        script.type = "text/javascript";
        script.id = "ac_form_script";

        // Override Script functions
        window._show_thank_you = () => {
          script.remove();
          removeTimeout();
          resolve(true);
        };
        window._show_error = (id, message, html) => {
          script.remove();
          removeTimeout();
          reject(message);
        };

        const removeTimeout = () => {
          clearTimeout(timeout);
        };

        //Append Script to be executed
        document.querySelector("head").appendChild(script);
      } else {
        reject("Please, complete all mandatory fields");
      }
    });
  }

  getFormURL(values) {
    return (
      "https://rocketdistributors.activehosted.com/proc.php?" +
      this.serializeValues(this.hiddenFields) +
      this.serializeValues(values) +
      "jsonp=true"
    );
  }

  serializeValues(values) {
    return Object.keys(values).reduce(
      (acc, current) =>
        acc +
        this.getAliasName(current) +
        "=" +
        encodeURIComponent(values[current]) +
        "&",
      ""
    );
  }

  validate(values) {
    let valid = true;
    this.fields.forEach((field) => {
      if (
        field.required &&
        (typeof values[field.name] !== "string" ||
          values[field.name].trim() === "")
      )
        valid = false;
    });
    return valid;
  }

  response(success, reason) {
    return { success, reason };
  }

  getAliasName(alias) {
    return this.alias.find((a) => a.name === alias)?.value || alias;
  }

  onSuccess() {}
}

export default ActiveCampaignForm;
