import React, { useEffect, useState } from 'react';
import Disclaimer from './disclaimer';

export default function CookiesDisclaimer () {
  const [show, setShow] = useState(false);
  
  useEffect(() => {
    if( getCookie("CookieScriptConsent") === null )
      setShow(true);
  }, []);

  const acceptAllCookies = () => {
    if(typeof window.CookieScript !== "undefined" ) {
      const cookieScript = new window.CookieScript();
      cookieScript.acceptAllAction();
      setShow(false);
    }
  }
  const acceptCoockies = () =>
  {
    if(document.getElementById('cookiescript_injected')) {
      document.getElementById('cookiescript_injected').style.display = 'block';
      setShow(false);
    }
  }
  
  if(!show)
    return null
  else
    return <Disclaimer handleDisclaimer={acceptCoockies} acceptAllCookies={acceptAllCookies}/>
}

function getCookie(name, cookies = document.cookie) {
    var dc = cookies;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
    }
    else
    {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
        end = dc.length;
        }
    }
    // because unescape has been deprecated, replaced with decodeURI
    //return unescape(dc.substring(begin + prefix.length, end));
    return decodeURI(dc.substring(begin + prefix.length, end));
}