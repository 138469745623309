import React from "react";

const About = () => (
  <div className="about container my-3 py-5" id="about">
    <h2 className="my-4">About</h2>
    <p>
      <b>We’re Rocket Distributors. </b>
      We partner with the best manufacturers in the world to bring their
      products to the United States, disrupting antiquated supply chains and old
      ways of doing business in the process.{" "}
    </p>
    <p>
      Modern, forward-looking businesses rely on Rocket to trim the fat from
      their supply chains and increase margins by using factory-direct,
      direct-to-business (D2B) distribution powered by Rocket.
    </p>
    <p>
      Get in touch to learn how we might be able to help your business procure
      more efficiently, externalize inventory, and save money. We’d love to hear
      from you.
    </p>
  </div>
);

export default About;
