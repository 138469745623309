import React from "react";
import img_bg from "../../../assets/Plain-Delivery-Rocket-Distributors-bg.jpg";
// import lift from "../../../assets/lift.png";
import lift from "../../../assets/Packaging-Rocket-Distributors.png";

const side_data = [
  {
    title: "For Manufacturers:",
    desc: `Rocket provides U.S. distribution solutions for best practice, category-leading manufacturers around the world.  Access online and offline sales outlets for your products through 
  our integrated omnichannel approach and leave the selling to us.`,
    btn: { click: () => {}, txt: "Inquire Now" },
  },
  {
    title: "For Businesses:",
    desc: `Simplify your supply chain and bring factory-direct savings to your business. 
  Rocket’s sophisticated just-in-time supply  programs and integrated fulfillment solutions reduce 
  your inventory costs and can lower your dependency on local distributors.  `,
    btn: { click: () => {}, txt: "Register Now" },
  },
  {
    title: "For Consumers:",
    desc: `Rocket's direct-to-consumer (D2C) ecommerce network brings the world's best 
  products straight to your home. When you buy from Rocket, you can be assured that you are 
  buying only ethically sourced, fair-trade products from vetted factories that pass 
  our rigorous quality and practice standards.  `,
    btn: { click: () => {}, txt: "Submit Email", class: "btn-danger" },
  },
];

const Header = () => (
  <div className="p-0 container-fluid first-container header__container last-container">
    <Head />
    {side_data.map((item, key) => (
      <SideContent key={`side_${key}`} data={item} />
    ))}
  </div>
);

const Head = () => (
  <div
    className="head text-right"
    style={{ backgroundImage: `url(${img_bg})` }}
  >
    <div>
      <h1>
        The world <small>is getting smaller.</small>
      </h1>
      <div className="hr"></div>
      <h3>Is your supply chain?</h3>
    </div>
    {/* <p>Rocket brings the factory's dock to your doorstep.</p> */}
    <img src={lift} alt="Lift " className="xs_hidden" />
  </div>
);

const SideContent = ({ data }) => (
  <div className="side ">
    <h4>{data.title}</h4>
    <p>{data.desc}</p>
  </div>
);

export default Header;
