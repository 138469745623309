import React from "react";
import vector_left from "../../../assets/vector-left.svg";
import vector_right from "../../../assets/vector-right.svg";
import image_bulkmask from "../../../assets/image-phone.jpg";
import logo from "../../../assets/Logo-Final.svg";
import logo_rd from "../../../assets/rdlogo.svg";
import image_rocket from "../../../assets/handshake-in-medical-gloves-HZ7B8GL.png";
import image_mask_lvl1 from "../../../assets/brands/rd-mask-lvl1.jpg";
import image_mask_lvl3 from "../../../assets/brands/rd-mask-lvl3.jpg";
import image_glove from "../../../assets/brands/rd-glove.jpg";
import ProductCard from "../../cards/product-card";

const RD_Products = [
  {name: "3ply level 1 surgical masks 510k", image: image_mask_lvl1, link: "https://rocket.direct/product-categories/safety/3-ply-level-1-surgical-masks-510k"},
  {name: "3ply level 3 surgical masks 510k", image: image_mask_lvl3, link: "https://rocket.direct/product-categories/safety/3-ply-level-3-surgical-masks-510k"},
  {name: "4mil disposable nitrile gloves", image: image_glove, link: "https://rocket.direct/product-categories/safety/4-mil-disposable-nitrile-gloves"},
]

const Brands = () => (
  <div className="brands container mt-3 " id="brands">
    <div className="title-wrapper">
      <div className="vector-left">
        <img src={vector_left} />
      </div>
      <div className="vector-right">
        <img src={vector_right} />
      </div>
      <div className="title-brands text-center pb-5">
        <h2>
          Meet the <strong>Rocket brands</strong>
        </h2>
      </div>
    </div>
    <div className="row rocket-wrapper mb-5">
      {console.log(image_rocket)}
      <div className="col-md-5 image-brands" style={{backgroundImage: `url(${image_rocket})`}}>
        
      </div>
      <div className="col-md-7">
        <div className="rocket-content">
          <div className="rocket-review">
          <div className="logo-rocket mt-4">
            <img src={logo_rd} />
          </div>
          <h3>
            Purchase Medical, Safety, and Hygiene Products. At the speed of
            Rocket.{" "}
          </h3>
          <p className="d-none d-md-block">
            Rocket.direct showcases our carefully vetted products in an intuitive
            online platform. We partner with the best manufacturers in the world
            to bring their products to the United States, disrupting antiquated
            supply chains and old ways of doing business in the process.
          </p>
          <p className="d-block d-md-none">
            We created Rocket.direct to showcase our carefully vetted products in an 
            intuitive online platform. As a factory-direct business, 
            Rocket does not work with middlemen or brokers. 
          </p>
          <div className="button-rd mb-3">
            <a href="https://rocket.direct/">Learn more</a>
          </div>
          </div>
          <div class="row rocket-products">
            <div class="col-12">
              <h3 class="text-center mb-3 text-rocket ">Our Products</h3>
            </div>
            {
              RD_Products.map(product => (
                <div class="col-md-4 mb-4 mb-md-0">
                  <ProductCard name={product.name} image={product.image} link={product.link}/>
                </div>
              ))
            }
          </div>  
        </div>
      </div>
    </div>
    <div className="row brands-wrapper">
      <div className="col-md-6 image-brands">
        <img src={image_bulkmask} />
      </div>
      <div className="col-md-6">
        <div className="brands-content ">
          <div className="logo-brands pt-3">
            <img src={logo} />
          </div>
          <h3>Need reliable, recurring PPE supply for your organization?</h3>
          We are prioritizing orders and establishing guaranteed supply programs
          for businesses with frontline workers and organizations that tend to
          vulnerable segments of the population.
          <div className="button">
            <a href="https://bulkmasks.direct/">Learn more</a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Brands;
