import React from "react";
import { brake } from "../../../middleware/common-functions";

import kn95_img from "../../../assets/rocketppe_kn95.jpg";
import ply3_img from "../../../assets/rocketppe_3ply.jpg";
import kn95_img_mob from "../../../assets/rocketppe_kn95_mob.jpg";
import ply3_img_mob from "../../../assets/rocketppe_3ply_mob.jpg";
import ply3_product from "../../../assets/3-ply.png";
import kn95_product from "../../../assets/kn95.png";

const pageInfo = {
  kn95: {
    cover: { desktop: kn95_img, mob: kn95_img_mob },
    title: "KN95 Respiratory Mask",
    specifications: [
      "Outer-Layer Spunbond NWPP (50g), Mid-Layer PE/PP (50g) + Meltblown (2 x 25g), Inner-Layer Spunbond NWPP (30g)",
      "Latex-free TPU ear loops & ultrasonic construction ",
      "Moldable nose strip for optimal seal and fit",
      "Flow rate 85 L/min",
      "0.3 Micron Particulate Filtration Efficiency ≥ 95% ",
      "KN95 (GB2626-2006)",
    ],
    packaging: [
      "50 masks per carton",
      "Sealed in protective polybag",
      `Shipping case dimensions: 8.07" x 5.31" x 6.10" <br> (Contains 32 boxes - 1,600 masks total)`,
    ],
    capacity: "100 million masks per month",
    img: kn95_product,
  },
  ply3: {
    cover: { desktop: ply3_img, mob: ply3_img_mob },
    title: "3-Ply Surgical Mask",
    specifications: [
      "Outer-Layer Spunbond NWPP (25g), Mid-Layer Meltblown Fiber (25g), Inner-Layer Spunbond NWPP (25g)",
      "Highly breathable and lightweight with moldable nose strip",
      "Latex-free TPU ear loops & ultrasonic construction",
      "Provides fluid protection barrier (120 mm Hg)",
      "Bacterial Filtration Efficiency ≥ 98%",
      "Level 2 (ASTM F2100-11); Type IIR (EN14683)",
    ],
    packaging: [
      "50 masks per carton",
      "Sealed in protective polybag",
      `Shipping case dimensions: 8.26" x 10.39" x 10.39" <br>
      (Contains 50 boxes - 2,500 masks total)`,
    ],
    capacity: "150 million masks per month",
    img: ply3_product,
  },
};

const Head = () => {
  return (
    <div className="container px-0 px-md-3">
      <div className="row buttons_container no-gutters d-md-none">
        <div className="col-6">
          <a href="#section_kn95" className="btn btn-block ">
            KN95 masks
          </a>
        </div>
        <div className="col-6">
          <a href="#section_ply3" className="btn btn-block ">
            3-Ply masks
          </a>
        </div>
      </div>
      <div className="row no-gutters">
        {Object.keys(pageInfo).map((key, index) => {
          const mask = pageInfo[key];
          return (
            <div className="col-md-6" key={key} id={`section_${key}`}>
              <div className="ppe_page__head_img">
                <h3 className="ppe_page__title">{mask.title}</h3>
                <img
                  src={mask.cover.desktop}
                  className="img-fluid d-none d-md-block"
                />
                <img src={mask.cover.mob} className="img-fluid d-md-none" />
              </div>
              <div className={`specs ${index === 0 ? "left" : " right"}`}>
                <div className={`specs_title ${index > 0 && "d-md-none"}`}>
                  <h4>Specifications</h4>
                </div>
                <ul>
                  {mask.specifications.map((item, key) => (
                    <li key={`kn_spec${key}`}>{brake(item)}</li>
                  ))}
                </ul>
              </div>
              <div className={`specs ${index === 0 ? "left" : " right"}`}>
                <div className={`specs_title ${index > 0 && "d-md-none"}`}>
                  <h4>Packaging</h4>
                </div>
                <ul>
                  {" "}
                  {mask.packaging.map((item, key) => (
                    <li key={`kn_spec${key}`}>{brake(item)}</li>
                  ))}
                </ul>
              </div>
              <div className={`capacity ${index === 0 ? "left" : " right"}`}>
                <p>
                  Supply Capacity: <br /> {mask.capacity}
                </p>
              </div>
              <div className="col-12 py-5 products__section d-md-none">
                <h2 className="text-center">
                  Optional Bulk & Retail Packaging
                </h2>
                <img className="img-fluid" src={mask.img} alt="products" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Head;
