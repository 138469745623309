import ActiveCampaignForm from "./form";

export default function ActiveCampaignContact(values) {
  const form = new ActiveCampaignForm({
    fields: [
      { name: "fullname", required: true },
      { name: "email", required: true },
      { name: "phone", required: true },
      { name: "location", required: true },
      { name: "message", required: true },
      //{ name: "context", required: true },
    ],
    hiddenFields: {
      u: 66,
      f: 66,
      s: "",
      c: 0,
      m: 0,
      act: "sub",
      v: 2,
      or: "62ef4b8341746454df8d4b086c3614e2",
    },
    alias: [
      { name: "message", value: "field[45]" },
      { name: "location", value: "field[99]" },
    ],
  });

  return form.submit(values);
}
