import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";
import rocket_logo from "../../assets/logo.png";
const defButtonClasses = ["btn", "btn-lg"];
class Nav extends Component {
  constructor(props) {
    super(props);
    this.collapseButton = this.collapseButton.bind(this);
    this.state = {
      toggled: true,
      toggled_b: false,
      toggledFirst: false,
      assignedClasses: [
        "navbar",
        "navbar-expand-md",
        "fixed-top",
        "main_navbar",
        "scroll",
      ],
      buttonClasses: [...defButtonClasses],
    };
  }
  componentDidMount() {
    ///uncomment to make transition
    // this.updateTransapency();
    // /// top nav animation
    // document.getElementsByTagName("body")[0].onscroll = () => {
    //   this.updateTransapency();
    // };
  }

  updateTransapency = () => {
    const assignedClasses = [...this.state.assignedClasses];
    const newButtonClasses = [...defButtonClasses];
    let scroll_value =
      document.documentElement.scrollTop || document.body.scrollTop;
    scroll_value =
      document.documentElement.scrollTop || document.body.scrollTop;
    if (
      (!this.state.toggledFirst && scroll_value >= 145) ||
      (scroll_value > 145 && this.state.toggled_b)
    ) {
      assignedClasses.pop();
      assignedClasses.push("material-shadows");
      newButtonClasses.push("btn-primary");
      this.setState({
        toggled_b: false,
        toggled: true,
        toggledFirst: true,
        assignedClasses,
        buttonClasses: newButtonClasses,
      });
    } else if (scroll_value <= 80 && this.state.toggled) {
      newButtonClasses.push("btn-light");
      assignedClasses.pop();
      assignedClasses.push("transparent");
      this.setState({
        toggled_b: true,
        toggled: false,
        assignedClasses,
        buttonClasses: newButtonClasses,
      });
    }
  };

  collapseButton() {
    var x = document.getElementById("navbarCollapse");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }

  render() {
    return (
      <nav className={this.state.assignedClasses.join(" ")} id="main_navbar">
        <div className="nav__background ">
          <div></div>
          <div></div>
        </div>
        <div className="container">
          <Link className="navbar-brand  smooth_scroll" to="/">
            <img src={rocket_logo} className="img-fluid" />
          </Link>
          <button
            onClick={this.collapseButton}
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fas fa-bars"></i>
          </button>
          <div className="collapse navbar-collapse " id="navbarCollapse">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link className="nav-link smooth_scroll" to="/#welcome">
                  Solutions
                </Link>
              </li>
              {/*  <li className="nav-item">
                <Link className="nav-link smooth_scroll" to="/ppe">
                  Rocket PPE
                </Link>
              </li> 
              <li className="nav-item brands-mav">
                <div className="nav-link smooth_scroll" to="/brands">
                  Brands
                  <div className="brands-items">
                    <li className="nav-subitem">
                      <Link
                        className="nav-link smooth_scroll"
                        to="/brands/rocket-direct"
                      >
                        Rocket.direct
                      </Link>
                    </li>
                    <li className="nav-subitem">
                      <Link
                        className="nav-link smooth_scroll"
                        to="/brands/bulkmask-direct"
                      >
                        Bulkmask.direct
                      </Link>
                    </li>
                  </div>
                </div>
              </li>*/}
              <li className="nav-item">
                <Link className="nav-link smooth_scroll about-nav" to="/#about">
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link smooth_scroll" to="/#contact">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className="d-md-flex d-none align-items-center justify-content-between"></div>
        </div>
      </nav>
    );
  }
}

export default Nav;
