import React from "react";
import Header from "./header";
import Banner from "./baner";
import Welcome from "./welcome";
import Brands from "./brands";
import Contact from "./contact";
import About from "./about";

const HomePage = () => (
  <React.Fragment>
    <Header />
    <Banner />
    <Welcome />
    {/*<Brands /> */}
    <About />
    <Contact />
  </React.Fragment>
);

export default HomePage;
