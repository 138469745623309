import React, { useState } from "react";
import axios from "axios";
import rocket_icon from "../../../assets/icons/rocket-icon-png-6.png";
import ActiveCampaignContact from "../../../lib/active-campaign/contact";
import { Spinner } from "reactstrap";

const formData = [
  { type: "text", name: "name", title: "Name", value: "" },
  { type: "email", name: "email", title: "Email", value: "" },
  { type: "tel", name: "phone", title: "Phone", value: "" },
  { type: "text", name: "location", title: "Location", value: "" },
];

const formInfoStart = {
  name: "",
  email: "",
  phone: "",
  location: "",
  msg: "",
};

const Contact = () => {
  const [formInfo, setFormInfo] = useState(formInfoStart);
  const [sended, setSended] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const changeHandler = (ev) => {
    const currentForm = { ...formInfo };
    const type = ev.target.dataset.type;
    const value = ev.target.value;
    currentForm[type] = value;
    setFormInfo(currentForm);
  };

  const handelSubmit = () => {
    const url = process.env.REACT_APP_API_URL + "api/v1/contact";
    setError("");

    if (!validate()) return setError("Please, complete all fields.");

    setLoading(true);
    ActiveCampaignContact({
      fullname: formInfo.name,
      email: formInfo.email,
      phone: formInfo.phone,
      location: formInfo.location,
      message: formInfo.msg,
    })
      .then(() => axios.post(url, { contact_info: formInfo }))
      .then(() => {
        setSended(true);
      })
      .catch((err) => {
        setError(
          "Failed to send message. Please try again: " + err?.message || ""
        );
        console.log("err", err);
      })
      .finally(() => setLoading(false));
  };

  const validate = () => {
    if (
      typeof formInfo.email === "string" &&
      formInfo.email.trim() !== "" &&
      typeof formInfo.name === "string" &&
      formInfo.name.trim() !== "" &&
      typeof formInfo.location === "string" &&
      formInfo.location.trim() !== "" &&
      typeof formInfo.phone === "string" &&
      formInfo.phone.trim() !== "" &&
      typeof formInfo.msg === "string" &&
      formInfo.msg.trim() !== ""
    )
      return true;
    else return false;
  };

  return (
    <div className="contact_section " id="contact">
      <img src={rocket_icon} alt="rocket icon" className="rocket_icon" />
      <div className="container">
        <h2> {sended ? "Thank you" : "Contact"}</h2>

        <div className="row justify-content-center">
          <div className="col-md-8">
            {sended ? (
              <Sended />
            ) : (
              <ContactForm
                formInfo={formInfo}
                changeHandler={changeHandler}
                handelSubmit={handelSubmit}
                loading={loading}
              />
            )}
          </div>
        </div>
        {<p className="mt-3"> {error}</p>}
      </div>
    </div>
  );
};

const Sended = () => (
  <div className="sended_contact w-100 text-center text-white">
    We received your inquiry. A member of our team will be in touch with you as
    soon as possible.
  </div>
);

const ContactForm = ({ formInfo, changeHandler, handelSubmit, loading }) => (
  <div className="row">
    <div className="col-md-6">
      {formData.map((item, key) => (
        <input
          key={`form_item_${key}`}
          type={item.type}
          data-type={item.name}
          value={formInfo[item.name]}
          className="form-control mb-2"
          placeholder={item.title}
          onChange={changeHandler}
        />
      ))}
    </div>

    <div className="col-md-6">
      <label>Message</label>
      <textarea
        className="form-control"
        data-type="msg"
        rows="6"
        onChange={changeHandler}
        value={formInfo.msg}
      ></textarea>
    </div>
    <div className="col-12 text-center mt-5">
      <button className="btn  px-5" onClick={handelSubmit}>
        {!loading ? "Send" : <Spinner size="sm">Loading...</Spinner>}
      </button>
    </div>
  </div>
);

export default Contact;
