import React from "react";
import { Link } from "react-router-dom";

const Baner = () => (
  <div className=" baner">
    <h3 className="text-danger font-weight-bold mb-3">
      Custom Products & Tailored Service
    </h3>
    <p>
      We realize there is rarely a one-size-fits-all approach to doing business.
      Product flexibility, customizability, and client-specific service
      solutions are at the heart of what we do.
    </p>
    {/*<Link to="/ppe">Learn More</Link>*/}
  </div>
);

export default Baner;
