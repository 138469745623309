import React from "react";
const infoBoxes = [
  { txt: "Direct-to-Business Supply Chain" },
  { txt: "Inventory-as-a-Service (IasS)" },
  { txt: "Turnkey Fulfillment" },
  { txt: "Customized Packaging & Labeling" },
  { txt: "On-Demand Product Sourcing " },
  { txt: "Supply Chain Diversification" },
];

const Welcome = () => (
  <div className="container welcome text-center" id="welcome">
    <p>
      Rapid, just-in-time supply and distribution solutions that give your
      company an edge.{" "}
    </p>
    <h2>
      Welcome to business at the speed of{" "}
      <span className="text-danger">Rocket</span>.{" "}
    </h2>
    <div className="welcome__container_info">
      {infoBoxes.map((item, key) => (
        <div key={`info_${key}`} className="welcome__info">
          {item.txt}
        </div>
      ))}
    </div>
  </div>
);

export default Welcome;
