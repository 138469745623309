
import React from 'react';
/*import Button from '../../../../form/button/button';
import Typography from '../../../../ui/typography';
import { Row, Col, Container } from 'react-bootstrap';
import styles from './cookies.module.scss';
*/
import { Row, Col, Container } from 'reactstrap';
const styles = {};

export default function Disclaimer({ handleDisclaimer, acceptAllCookies })
{
    return (
        <div className="disclaimer">
            <div className="container-disclaimer">
            <Container >
            <Row>
                <Col md={8}>
                <p className="text">
                By using this website you agree to the storing of cookies on your device
                to enhance site navigation, analyze site usage, and assist in our
                marketing efforts.
                </p>
                </Col>
                <Col md={4} className="accept">
                <button onClick={acceptAllCookies} width="200px" margin="0px 0px 10px 0px">
                    Accept All
                </button>
                <button onClick={handleDisclaimer} width="200px">
                    Setup Cookies
                </button>
                </Col>
            </Row>
            </Container>
            </div>
        </div>
    )
}