import React, { useState } from 'react';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';
import "./pdf-renderer.css";

export default function PDFRenderer({src})
{
    const [pages, setPages] = useState(0);

    const handlePDFLoad = ({numPages}) =>
    {
        setPages(numPages)
    }

    const getPages = () =>
    {
        const result = [];
        for(let i = 0; i < pages; i++)
        {
            result.push(
                <PageContainer>
                    <Page height="1000" pageNumber={i + 1} style={{backgroundColor: 'red'}}/>
                </PageContainer>
            );
        }
        return result;
    }

    return (
        <Document file={src} onLoadSuccess={handlePDFLoad}>
            {getPages()}
        </Document>
    );
}

function PageContainer({children}) 
{
    return(
        <div className="page">
            {children}
        </div>
    )
}