import React, {useState} from 'react'
import PDFRenderer from '../../pdf/pdf-renderer';

export default function Certifications({pdf, title})
{

    return (
        <div className="page-gray">
            <div className="title-with-arrow">
                <h2 className="title-content text-center mb-3">{title}</h2>
            </div>
        <div className="pdf-container" style={{overflowX: 'scroll'}}>
            <PDFRenderer src={pdf}/>
        </div>
        </div>
    );
}